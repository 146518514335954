import React,{useState,useContext,createContext, useEffect, useRef} from 'react'

import  { setOptions} from '@mobiscroll/react';    
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { doc,addDoc, updateDoc, getDoc,  query, setDoc, collection,  onSnapshot, where, Timestamp } from 'firebase/firestore';

import imageCompression from 'browser-image-compression';
import { getStorage, ref, uploadBytes,getDownloadURL,uploadString } from "firebase/storage";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UserAuth } from '../../context/AuthContext'
const PaperlessContext = createContext();

export const PaperlessContextProvider = ({ children }) => {
 
    const storage = getStorage(); 
    const { gearedUser } = UserAuth() || {};
    const [paperlessState, setPaperlessState] = useState('none');
    const [freightBill, setFreightBill]= useState({ID:''});
    const [loadOrders, setLoadOrders]= useState([]);
    const [truckFreightBills, setTruckFreightBills]= useState([]);
    const [submitStatus, setSubmitStatus] = useState('Entry');
    const [homeDate, setHomeDate]= useState(''); 
    const [approveType, setApproveType] = useState('');
    const [pdfUrl, setPdfUrl]=useState('');
    const [approverNotes, setApproverNotes]=useState(null);
    const [driverNotes, setDriverNotes]=useState(null);
    const [activeIndex, setActiveIndex] = useState(1);
    const [navListItems, setNavListItems] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const attachmentsRef = useRef(null);
    const unsubscribeFreightBillRef = useRef(null)
    const trucksFreightBillRef =useRef(null);
    const navigate = useNavigate();
    const loadOrderListenerRef = useRef(null);
    const attachmentListenerRef = useRef(null);

    const uploadPDFFile = async(document)=>{
        let storageRef = ref(storage, 'attachments/'+freightBill.companyID+'/FreightBills/' +freightBill.ID+'/PDFs/'+freightBill.ID+approveType+'Sign.pdf');
        const snapshot = await uploadString(storageRef, document, 'base64');
        const url = await getDownloadURL(storageRef);
        return url;
    }

    pdfFonts['Roboto-Medium'] = {
        normal: 'Roboto-Medium.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Medium.ttf',
        bolditalics: 'Roboto-Medium.ttf',
    };
  
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    function formatDate(date, divider, format) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        if(format==='YYYY/MM/DD')return [year, month, day].join(divider);
        else return [month, day,year].join(divider);
    }
    const getDifferenceInMinutes = (start, end) =>{
    console.log('start = ',start)
    if(checkGearedTime(start) && checkGearedTime(end)){
        let differenceMinutes =(end.getTime() - start.getTime()) / 60000;
        return Math.round(differenceMinutes);
    }else return 0;
    }

  

    const viewPDF = ()=>{
        createPDF('view');
        setActiveIndex(2);
        setPdfUrl('');
        setSubmitStatus('View PDF')
    }

    const createPDF =function(action){
        
    var truckname, trailername;
    // if(approveType=='Shipper')freightBill.approveShipperSignature = approveSignaturePad.toDataURL();
    //  if(approveType=='Receiver')freightBill.approveReceiverSignature = approveSignaturePad.toDataURL();
        

        if(freightBill.Trailer){if(freightBill.Trailer.Name==='No Trailer') trailername=''; else trailername=freightBill.Trailer.Name;}else trailername='';
        if(freightBill.Truck) {if(freightBill.Truck.Name==='No Truck')  truckname=''; else truckname= freightBill.Truck.Name;} else  truckname=''; 
        let CompanyHeader=  freightBill.Company.Address +", " + freightBill.Company.address2  +  "\nOffice: "  + freightBill.Company.CompanyPhone+ "   Fax: "  +freightBill.Company.Fax ;
        var haulerText= [{ bold:true, text:'Driver Name: '  }, freightBill.driverName];
        if(freightBill.Subhauler)  haulerText= [{ bold:true, text:'Sub-Hauler: ' },freightBill.haulerName]; 
        let firstRow=[{colSpan: 2, rowSpan:2, fontSize: 15, text:haulerText}, {}, { text:[{bold:true,text: 'Job #: ' }, freightBill.jobNO]}];
        
        let secondRow=[  {}, {}, { text:[{bold:true,text:'Date: '}, freightBill.JobDate]} ]
        let thirdRow=[{ text:[{bold:true,text:'Rate Type: '}, freightBill.PayType]}, { text:[{bold:true,text: 'Number of Loads: '}, freightBill.loads]}, { text:[{bold:true,text: 'Truck: '}, truckname]}];
        let shipperAndReceiverRow=[
            {fontSize: 15, text:[{bold:true,text:'Shipper: ' }, freightBill.Shipper.Name]},
            {fontSize: 15, text:[{bold:true, text:'Receiver: ' }, freightBill.Receiver.Name]}
        ];

        let fourthRow=[ {text:[{bold:true, text:'Material: '}, freightBill.materialName]},  {text:[{bold:true, text:'TruckType: ' }, freightBill.TruckType.Name]},  {text:[{bold:true, text:'Trailer: ' }, trailername]}];
        let sixthRow=[{text:[{bold:true, text:'Origin: '}, freightBill.loadSite]}, {text:[{bold:true, text:'Destination: ' }, freightBill.dumpSite]}];
        let seventhRow=[{text:[{bold:true, text:'Address: ' }, freightBill.loadAddress]}, {text:[{bold:true, text:'Address: ' }, freightBill.dumpAddress]}];
        
        let weightTable=[];
        let expenseTable=[];
        let weightTableHeaders=[
            {colSpan: 3, text: 'Weights', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {},
            {colSpan: 3, text: 'Load', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {},
            {colSpan: 3, text: 'Dump', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {}
        ];
        let weightTableHeaders2=[
            {text: 'Material', style: 'tableHeader', alignment: 'center'},
            {text: 'Scale Tag', style: 'tableHeader', alignment: 'center'},
            {text: 'Weight', style: 'tableHeader', alignment: 'center'},
            {text:'Arrive', style: 'tableHeader', alignment: 'center'},
            {text: 'Depart', style: 'tableHeader', alignment: 'center'},
            {text: 'Stand By', style: 'tableHeader', alignment: 'center'},
            {text: 'Arrive', style: 'tableHeader', alignment: 'center'},
            {text: 'Depart', style: 'tableHeader', alignment: 'center'},
            {text: 'Stand By', style: 'tableHeader', alignment: 'center'}
        ];
        let expenseTableHeaders=[
            {text: 'Description', style: 'tableHeader', alignment: 'center'},
            {text: 'Qty', style: 'tableHeader', alignment: 'center'},
            {text: 'Rate', style: 'tableHeader', alignment: 'center'},
            {text: 'Total', style: 'tableHeader', alignment: 'center'}
            
        ];
    
        console.log('freightBill.approveShipperSignature = ', freightBill.approveShipperSignature)
        var driverSignatureImage={ width:265,text: '',  height:50}; 
        var approveSignatureImage={ width:265,text: '',  height:50};
        
        if(freightBill.driverSignature) driverSignatureImage={ width:265,image: freightBill.driverSignature, height:50};
        if(approveType==='Shipper') if(freightBill.approveShipperSignature)approveSignatureImage={ width:265,image: freightBill.approveShipperSignature, height:50};
        if(approveType==='Receiver') if(freightBill.approveReceiverSignature)approveSignatureImage={ width:265,image: freightBill.approveReceiverSignature, height:50};
        //    if(approveType=='Receiver')if(!freightBill.approveReceiverSignature)approveSignatureImage={ width:265,text: '',  height:50}; else approveSignatureImage={ width:265,image: freightBill.approveReceiverSignature, height:50};
    
    //  console.log('driverSignatureImage = ' +driverSignatureImage);
    //  console.log('approveSignatureImage = ' +approveSignatureImage);
        weightTable.push(weightTableHeaders);
        weightTable.push(weightTableHeaders2);
        expenseTable.push(expenseTableHeaders);
        
        let weightTableWidths=['*',55,50,55,55,35,55,55,35];
        let expenseTableWidths=['*','*','*','*'];
        for(var i=0; i<freightBill.Weights.length; i++){
            var materialName='';
            
            if(freightBill.Weights[i].Material){
                if(freightBill.Weights[i].Material.Name) materialName=freightBill.Weights[i].Material.Name;
                else materialName=freightBill.Weights[i].Material;
            } 
            var loadStart, loadEnd, dumpStart, dumpEnd;
            loadStart = convertReactDate(freightBill.Weights[i].loadStart);
            loadEnd = convertReactDate(freightBill.Weights[i].loadEnd);
            dumpStart = convertReactDate(freightBill.Weights[i].dumpStart);
            dumpEnd = convertReactDate(freightBill.Weights[i].dumpEnd);
            let weightTableRow=[
                {text: materialName, alignment:'center' },
                {text: freightBill.Weights[i].tagNO, alignment:'center' },
                {text: freightBill.Weights[i].weight, alignment:'right' },
                {text: loadStart, alignment:'center' },
                {text: loadEnd, alignment:'center' },
                {text: freightBill.Weights[i].excessLoad, alignment:'right' },
                {text: dumpStart, alignment:'center' },
                {text: dumpEnd, alignment:'center' },
                {text: freightBill.Weights[i].excessDump, alignment:'right' }
            ];
            weightTable.push(weightTableRow);
        }
        
        let totalWeightRow=[
            {colSpan: 2,bold:true, text: 'Total Weight:',  alignment: 'right'},
            {},
            {text:freightBill.tWeight,alignment: 'right'},
            {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
            {},
            {text: freightBill.totalExcessLoad,alignment: 'right'},
            {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
            {},
            {text: freightBill.totalExcessDump,alignment: 'right'}
        ];
        weightTable.push(totalWeightRow);
        console.log('weightTable = ', weightTable);
        
        let expenseRowCount=0;
        for(var j=0; j<freightBill.Expenses.length; j++){
            let expenseTableRow=[
                freightBill.Expenses[j].Name.Name,
                freightBill.Expenses[j].qty,
                freightBill.Expenses[j].rate,
                freightBill.Expenses[j].total
            ];
        if(freightBill.Expenses[j].Name!=='Stand By'){
            expenseRowCount++;
            expenseTable.push(expenseTableRow);
        } 
        }
        console.log('expenseTable = ',expenseTable);
        var startTime, endTime, arriveRoundTrip, departRoundTrip;
        startTime = convertReactDate(freightBill.startTime);
        endTime = convertReactDate(freightBill.endTime);
        arriveRoundTrip = convertReactDate(freightBill.arriveRoundTrip);
        departRoundTrip = convertReactDate(freightBill.departRoundTrip);
        let timeRows=[[{text:[{bold:true, text:'Start Time: '},startTime]},{text:[{bold:true, text:'End Time: ' }, endTime]}, {text:[{bold:true, text:'Total Hours: ' }, freightBill.grossHours]}, {text:[{bold:true, text:'Deduction: ' }, freightBill.lunch]}]];
        var timeRow2=[{},{}, {},{text:[{bold:true, text:'Hours: '}, freightBill.tHours]}  ];
        if(freightBill.PayType==='Hour' || freightBill.PayType==='Hour/Percent') timeRow2=[{text:[{bold:true, text:'Depart Load: '},departRoundTrip]},{text:[{bold:true, text:'Arrive Dump: ' }, arriveRoundTrip]}, {text:[{bold:true, text:'Running Time: ' }, freightBill.runningTime]},{text:[{bold:true, text:'Hours: '}, freightBill.tHours]}  ];
        timeRows.push(timeRow2);          
        
        var docDefinition = {
            content: [],
            pageMargins: [15, 55, 15, 0],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };
        

        docDefinition.header=[
            { margin: [0, 0, 0, 0],text:freightBill.Company.CompanyName,  fontSize: 19,  bold: true, alignment: 'center'},
            {margin: [0, -18, 15, 0],text: 'FB#: ' + freightBill.FBNO, alignment: 'right'}, 
            {  text: CompanyHeader, margin: [0, 2, 0, 0],  bold: true, alignment: 'center', fontSize: 10 }
        ];
        // docDefinition.content.push(freightBillHeader2);
        
        let freightBillInfo= {
            style: 'tableExample',
            table: { widths: ['*',  '*', '*'], body: [firstRow,secondRow,thirdRow,fourthRow]}
        };
        docDefinition.content.push(freightBillInfo);
        
        let freightBillShipperAndReceiver={
            style: 'tableExample',
            table: { widths: ['*',  '*'], body: [shipperAndReceiverRow,sixthRow,seventhRow ]}
        };
        docDefinition.content.push(freightBillShipperAndReceiver);
        
        let freightBillWeightTable={
            style: 'tableExample',
        
            table: {widths: weightTableWidths, body: weightTable } 
        }
        docDefinition.content.push( freightBillWeightTable);        
        let freightBillExpenseTable={
            style: 'tableExample',
            table: {widths: expenseTableWidths, body: expenseTable } 
        }
        if(expenseRowCount>0)docDefinition.content.push(freightBillExpenseTable);        
                
        let freightBillTimes={
            style: 'tableExample',
            table: {widths: ['*',  '*', '*', '*' ], body: timeRows } 
        }
        docDefinition.content.push(freightBillTimes);        
                
        let freightBillSignatureLabels={
            table: {
                widths: ['*', '*'],
                body: [
                    [{ text: [{ bold: true, text: 'Driver Name: ' }, freightBill.driverName] }, { text: [{ bold: true, text: 'Consignor Name: ' }, freightBill.signatureName] }],
                    [{ border: [false, false,false,false],  bold: true, text: 'Driver Signature:' }, { border: [false, false,false,false], bold: true, text: 'Consignor Signature:' }]
                ]
            }
        }
        docDefinition.content.push(freightBillSignatureLabels);        
                
        let freightBillSignatures={
            columns: [
                { width: 5, text: '' },
                driverSignatureImage,
                { width: 20, text: '' },
                approveSignatureImage
            ], 
        }
        docDefinition.content.push(freightBillSignatures);  
        if(!freightBill.approverComments)freightBill.approverComments='';
        
        let freightBillComments={
            table: {
                widths: ['*', '*'],
                body: [
                    [{text:[{bold:true, text:'Driver Comments: '},   freightBill.Comments ], alignment:'center' },
                    {text:[{bold:true, text:'Approver Comments: '},   freightBill.approverComments ], alignment:'center' }]
                ]
            }
        }
        
        docDefinition.content.push(freightBillComments);
        
        console.log('docDefinition = ' , docDefinition);

        pdfMake.createPdf(docDefinition).getBase64(async function(encodedString) {
            let base64PDF = encodedString;
            const PDFURL = await uploadPDFFile(base64PDF);
            if(PDFURL)setPdfUrl(PDFURL);
            if(action =='download') pdfMake.createPdf(docDefinition).download('FreightBill.pdf')
            if(action==='signout'){
            if(freightBill.emailForeman)  emailPDF(PDFURL)
                if(freightBill.textForeman) textPDF(PDFURL)
            }
            console.log('pdfUrl=', pdfUrl);
        });

        //  savePDF(action); 

    }

    const emailPDF= (PDFURL) =>{
    let tempDate= new Date();
    let realTempDate=  formatDate(tempDate.setDate(tempDate.getDate()), '/', 'MM/DD/YYYY');
    var newEmailPDF={
            
        from: freightBill.companyID + '<' +  'FreightBills@mail.geared.tech>',
        replyTo:'lauri@johnstrucking.com',
        to: freightBill.emailAddress,
        createdAt:realTempDate,
        createdBy:freightBill.driverName,
        URL: PDFURL,
        JobID:'',
        PDFType:'FreightBill',
        ParentID:freightBill.ID,
        Sent:false,
        subject:  'FreightBill from ' +freightBill.companyID,
        html: '<a href="'+ PDFURL+ '">Click this link to download PDF of FreightBilll </a>'

    }

    console.log('newEmailPDF = ', newEmailPDF);
    addDoc(collection(db, `Organizations/${freightBill.companyID}/EmailedPDFs`), newEmailPDF).then(function (docRef) {
            console.log("Emailed PDF created ", docRef.id);
        
        });
    }
    const textPDF=(PDFURL)=>{  
        var data = {
            OrganizationName: freightBill.companyID,
            FreightID:freightBill.ID,
            body:  'Click this link to download PDF of Freight Bill ' + PDFURL,
            from: '+16196399631',
            to: '+1'+freightBill.textPhoneNumber
        };
        fetch('https://geared.tech/text',{
            method: 'POST',   
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        }).then(function successCallback(response) {
            console.log('TEXT IS SENT', response);
        }, function errorCallback(response) {
            console.log('text faield to send = ', response);
        });
    }

    const checkGearedTime = (time) => {
        if(time){
        if (time!=='' && time.length!==5)return true; else return false;
        }else return false;
    }

    const updateAuditLog = async(tempFreightBill)=>{

        const secondsSinceEpoch = Math.round(Date.now() / 1000);
        let fullDate = new Date().toString();
    
        let newAuditLog={
        User:gearedUser.Name,
        ID:secondsSinceEpoch.toString(),
        DateAndTime: fullDate,
        ActivityType:"add Load on Mobile FB", 
        ObjectType:"FreightBills",
        Value: tempFreightBill.Weights
        }
        console.log(' newAuditLog =',  newAuditLog);
        console.log(' this looks like = ' + 'Organizations/'+ freightBill.companyID+'/AuditLogs/FreightBills/'+ tempFreightBill.ID+'/' +secondsSinceEpoch.toString());
    
        const auditLogRef = doc(db, 'Organizations/'+ freightBill.companyID+'/AuditLogs/FreightBills/'+ tempFreightBill.ID, secondsSinceEpoch.toString());
        setDoc(auditLogRef,  newAuditLog);
            
    }
    const checkWeightTimes = (freight,weight) => {
    
        if(checkGearedTime(weight.loadStart)) weight.loadStart =  weight.loadStart.toDate();
        if(checkGearedTime(weight.dumpStart)) weight.dumpStart =  weight.dumpStart.toDate();
        if(checkGearedTime(weight.dumpEnd)) weight.dumpEnd =  weight.dumpEnd.toDate();
        if(checkGearedTime(weight.loadEnd)) weight.loadEnd =  weight.loadEnd.toDate();

        if(weight.mobileloadStart==='Invalid Date')weight.mobileloadStart =weight.loadStart;
        if(weight.mobileloadEnd==='Invalid Date')weight.mobileloadEnd =weight.loadEnd
        if(weight.mobiledumpStart==='Invalid Date')weight.mobiledumpStart =weight.dumpStart
        if(weight.mobiledumpEnd==='Invalid Date')weight.mobiledumpEnd =weight.dumpEnd
    

        if(checkGearedTime(weight.mobileloadStart)) if(weight.mobileloadStart instanceof Timestamp) weight.mobileloadStart = weight.mobileloadStart.toDate();
        if(checkGearedTime(weight.mobileloadEnd)) if(weight.mobileloadEnd instanceof Timestamp) weight.mobileloadEnd = weight.mobileloadEnd.toDate();
        if(checkGearedTime(weight.mobiledumpStart)) if(weight.mobiledumpStart instanceof Timestamp)weight.mobiledumpStart = weight.mobiledumpStart.toDate();
        if(checkGearedTime(weight.mobiledumpEnd)) if(weight.mobiledumpEnd instanceof Timestamp)weight.mobiledumpEnd = weight.mobiledumpEnd.toDate();

    if(weight.loadStart) weight.loadStart= formatTimeToDate(freight, weight.loadStart);
    if(weight.mobileloadStart)  weight.mobileloadStart= formatTimeToDate(freight, weight.mobileloadStart);
        
    if(weight.loadEnd)  weight.loadEnd= formatTimeToDate(freight, weight.loadEnd);
    if(weight.mobileloadEnd) weight.mobileloadEnd= formatTimeToDate(freight, weight.mobileloadEnd);
        

    if(weight.dumpStart) weight.dumpStart= formatTimeToDate(freight, weight.dumpStart);
    if( weight.mobiledumpStart) weight.mobiledumpStart= formatTimeToDate(freight, weight.mobiledumpStart);
        

    if(weight.dumpEnd) weight.dumpEnd= formatTimeToDate(freight, weight.dumpEnd);
    if( weight.mobiledumpEnd)  weight.mobiledumpEnd= formatTimeToDate(freight, weight.mobiledumpEnd);
        


        if(weight.Material)weight.materialName=weight.Material.Name;

    }

    const fetchFreightBill = async (id,orgName) => {
    
        return new Promise(function(resolve,reject){
            console.log('orgName = ' + orgName)
            console.log('id = ' + id)
            console.log('freightBill =' ,freightBill)
            if(id===freightBill.ID){
                console.log('THIS SHOULD NOT FIRE!!!', freightBill);
                if(!freightBill.startTimePaid && !freightBill.Subhauler)  setSubmitStatus('Clock-In');  
                if(freightBill.Material)freightBill.materialName=freightBill.Material.Name;
                return resolve(freightBill);
            } 
            const docRef = doc(db, 'Organizations/'+orgName+'/DriverFreightBills', id);
        
            
            let firstTime = true;
            if (unsubscribeFreightBillRef.current) unsubscribeFreightBillRef.current();
            
            unsubscribeFreightBillRef.current = onSnapshot(docRef, async(docSnap) => {
                console.log("Current data: ", docSnap.exists());
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log("source ",source);
                if (docSnap.exists() && source ==="Server") {
                    docSnap.data().ID=docSnap.id;
                
                    let paperlessFreight=docSnap.data();
                    console.log('GETTING Fb and its  = ',   paperlessFreight)
                
                    if(!paperlessFreight.totalYardHours)paperlessFreight.totalYardHours=0;
                    if(!paperlessFreight.travelTime)paperlessFreight.travelTime=0;
                    if(!paperlessFreight.startTravelTime)paperlessFreight.startTravelTime='';
                
                    if(checkGearedTime(paperlessFreight.startTimePaid))paperlessFreight.startTimePaid=  paperlessFreight.startTimePaid.toDate();
                    if(checkGearedTime(paperlessFreight.endTimePaid))paperlessFreight.endTimePaid=  paperlessFreight.endTimePaid.toDate();
                    
                    if(checkGearedTime(paperlessFreight.startTime))  paperlessFreight.startTime = paperlessFreight.startTime.toDate();
                    if(checkGearedTime(paperlessFreight.endTime))paperlessFreight.endTime=  paperlessFreight.endTime.toDate();
                    if(checkGearedTime(paperlessFreight.departRoundTrip))paperlessFreight.departRoundTrip=  paperlessFreight.departRoundTrip.toDate();
                    if(checkGearedTime(paperlessFreight.arriveRoundTrip))paperlessFreight.arriveRoundTrip=  paperlessFreight.arriveRoundTrip.toDate();

                    if(paperlessFreight.startTimePaid) paperlessFreight.startTimePaid=  formatTimeToDate(paperlessFreight, paperlessFreight.startTimePaid);
                    if(paperlessFreight.endTimePaid)paperlessFreight.endTimePaid=  formatTimeToDate(paperlessFreight, paperlessFreight.endTimePaid);
                        
                    if(paperlessFreight.startTime) paperlessFreight.startTime = formatTimeToDate(paperlessFreight, paperlessFreight.startTime);
                    if(paperlessFreight.endTime) paperlessFreight.endTime=  formatTimeToDate(paperlessFreight, paperlessFreight.endTime);
                    if(paperlessFreight.departRoundTrip)paperlessFreight.departRoundTrip=  formatTimeToDate(paperlessFreight, paperlessFreight.departRoundTrip);
                    if(paperlessFreight.arriveRoundTrip) paperlessFreight.arriveRoundTrip=  formatTimeToDate(paperlessFreight, paperlessFreight.arriveRoundTrip);

                    
                    if(paperlessFreight.Foreman?.Phone)paperlessFreight.shipperPhone = paperlessFreight.Foreman.Phone;
                    if(paperlessFreight.Foreman?.Email)paperlessFreight.shipperEmail = paperlessFreight.Foreman.Email;
                    
                    
                    if(paperlessFreight.ReceiverForeman?.Phone) paperlessFreight.receiverPhone = paperlessFreight.ReciverForeman.Phone;
                    if(paperlessFreight.ReceiverForeman?.Email) paperlessFreight.receiverEmail = paperlessFreight.ReciverForeman.Email;
                    
                    for (let i = 0; i< paperlessFreight.Weights.length; i++)checkWeightTimes(paperlessFreight,paperlessFreight.Weights[i]);
                
                    
                    if(paperlessFreight.Material)paperlessFreight.materialName=paperlessFreight.Material.Name;
                    console.log('paperlessFreight.materialName = ' + paperlessFreight.materialName);
                    paperlessFreight.loaded=true;
                
                    if((!paperlessFreight.startTimePaid && !paperlessFreight.Subhauler) || !paperlessFreight.Weights.length)  setSubmitStatus('Clock-In'); 
                    if(paperlessFreight.QuillFBNotes){
                        if(paperlessFreight.QuillFBNotes.length>0){
                            setDriverNotes(quillOpsToHtml(paperlessFreight.QuillFBNotes))
                        }
                    }
                    if(paperlessFreight.QuillApproverNotes){
                        if(paperlessFreight.QuillApproverNotes.length>0){
                            setApproverNotes(quillOpsToHtml(paperlessFreight.QuillApproverNotes))
                        }
                    }
                    console.log('RETURNING THE SNAP.DATA!!! ')
                    if(firstTime){
                        firstTime=false;
                        if(!paperlessFreight.Received){
                            let tempdate = new Date;
                            let realTempDate=  formatDate(tempdate.setDate(tempdate.getDate()), '/', 'MM/DD/YYYY');
                            if (Number(tempdate.getMinutes()) < 10) realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + '0' + tempdate.getMinutes();
                            else realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + tempdate.getMinutes();
                            console.log('trealTempDate= ', realTempDate);
                            paperlessFreight.Received = true;
                        
                            var newReceivedFreight = { FreightID:  paperlessFreight.ID, Received: true, ReceivedTime: realTempDate };
                            let receivedFreightRef = doc(db, 'Organizations/'+orgName+'/ReceivedFreightBills', paperlessFreight.ID);
                            setDoc(receivedFreightRef,  newReceivedFreight);
                            if(paperlessFreight.Cancelled ) alert('Thank you, your dispatcher has been notified you have received this cancellation.')
                        }
                    
                        const loadOrderRef = doc(db, 'Organizations/'+paperlessFreight.companyID+'/LoadOrders',paperlessFreight.dispatchID);
                        if(loadOrderListenerRef?.current)loadOrderListenerRef.current();
                        loadOrderListenerRef.current= onSnapshot(loadOrderRef, async(docSnap) => {
                            console.log("Current load order data: ",  docSnap.data());
                            const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                    
                            if (docSnap.exists() && source ==="Server") {
                                docSnap.data().ID=docSnap.id;
                                let tempLoadOrders=docSnap.data();
                                setLoadOrders(tempLoadOrders.LoadOrders)
                                console.log('got the load oorders = ' , tempLoadOrders.LoadOrders)
                            }
                        });
                
                        const q = query(collection(db, "Organizations/"+orgName+"/Attachments"),where("dispatchID", "==",paperlessFreight.dispatchID));

                        if(attachmentListenerRef?.current)attachmentListenerRef.current();
                        attachmentsRef.current=[];
                        attachmentListenerRef.current = onSnapshot(q, (querySnapshot) => {
                            querySnapshot.docChanges().forEach((change) => {
                        
                                let tempAttach= change.doc.data();
                                tempAttach.ID=change.doc.id;
                                if (change.type === "added") {
                                    attachmentsRef.current.push(tempAttach);
                                }
                                if (change.type === "modified") {
                                    const attachIndex = attachmentsRef.current.findIndex(a => a.ID === tempAttach.ID);
                                    attachmentsRef.current[attachIndex]={...tempAttach};
                                
                                }
                                if (change.type === "removed") {
                                    const attachIndex = attachmentsRef.current.findIndex(a => a.ID === tempAttach.ID);
                                    attachmentsRef.current.splice(attachIndex,1);
                                }
                            });
                            setAttachments([...attachmentsRef.current]);
                        });
                    }
                    setFreightBill({...paperlessFreight})
                    console.log('we are now setting freightbill and it = ', paperlessFreight)
                    return resolve(paperlessFreight);

                
                }
            });
        })
    

    };
    const fetchTrucksFreightBill = async (tempFreightBill,orgName, id) => {
        console.log('tempfreightBill = ', tempFreightBill);
        const q = query(collection(db, "Organizations/"+orgName+"/DriverFreightBills"),where("phoneNumber", "==", tempFreightBill.phoneNumber),where("Driver", "==",tempFreightBill.Driver),where("dispatchID", "==",tempFreightBill.dispatchID));
        if (unsubscribeFreightBillRef.current) unsubscribeFreightBillRef.current();
        trucksFreightBillRef.current=[];
        tempFreightBill.TrucksAssigned = 0;
        
        console.log('about to runt eh truck freightbill querye');
        let tempdate = new Date;

        unsubscribeFreightBillRef.current = onSnapshot(q, (querySnapshot) => {
        
            const cities = [];
            querySnapshot.docChanges().forEach((change) => {
        
                let truckFreightBill= change.doc.data();
                if (change.type === "added") {
                tempFreightBill.TrucksAssigned++;
                trucksFreightBillRef.current.push(truckFreightBill);  
                }
                if (change.type === "modified") {
            
                console.log('truckFreightBills.length = ' + truckFreightBills.length);
                for(var q=0; q<trucksFreightBillRef.current.length; q++){
                    if(trucksFreightBillRef.current[q].ID===truckFreightBill.ID)trucksFreightBillRef.current[q]=truckFreightBill;
                }
                    console.log("Modified city: ", change.doc.data());
                }
                if (change.type === "removed") {
            
                for(var q=0; q<trucksFreightBillRef.current.length; q++){
                    if(trucksFreightBillRef.current[q].ID==truckFreightBill.ID)trucksFreightBillRef.current.splice(q,1)
                    tempFreightBill.TrucksAssigned--;
                }
                }
                console.log('id  =', id)
                console.log('found truckFreight =', truckFreightBill)
            
                if(!truckFreightBill.Received){
                let realTempDate=  formatDate(tempdate.setDate(tempdate.getDate()), '/', 'MM/DD/YYYY');
                if (Number(tempdate.getMinutes()) < 10) realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + '0' + tempdate.getMinutes();
                else realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + tempdate.getMinutes();
                console.log('trealTempDate= ', realTempDate);
                truckFreightBill.Received = true;
            
                var newReceivedFreight = { FreightID:  truckFreightBill.ID, Received: true, ReceivedTime: realTempDate };
                let receivedFreightRef = doc(db, 'Organizations/'+orgName+'/ReceivedFreightBills', truckFreightBill.ID);
                setDoc(receivedFreightRef,  newReceivedFreight);
                }
            
            });
            console.log('setting truck freight bills = ', trucksFreightBillRef.current);
    
            setTruckFreightBills(trucksFreightBillRef.current);
            return new Promise(function(resolve,reject){
                console.log('when does this run?!?!');
                console.log('and the real truck freight bills = ', truckFreightBills);
                setFreightBill(prevState => ({...prevState,  TrucksAssigned: trucksFreightBillRef.current.length }));
                return resolve( trucksFreightBillRef.current);
            });
        
            }); 
        
    }

    function convertTimeStamp(date) {
        const formatDate = new Date(
            date.seconds * 1000 + date.nanoseconds / 1000000
        );
        return formatDate.toLocaleTimeString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
    const fetchNoDispatch =async(id,orgname)=>{
        
 
      console.log('we are fetching dispatch for orgname = ', orgname);
      console.log('and the id = '+ id);
      const docRef = doc(db, 'Organizations/'+orgname+'/UnassignedTexts', id);
      const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            var UnassignedText = docSnap.data();
            UnassignedText.read=true;
            console.log('we got the nodispatch object like this ', UnassignedText);
            setFreightBill(UnassignedText);
            const noDispRef = doc(db, 'Organizations/'+ orgname+'/UnassignedTexts', id);
          
            updateDoc(noDispRef, {"read": true}).then((tempFreight) => {
              
            })
      
          }else console.log('this text no EXIST');
     


    
    }

    const formatTimeToDate = (freight, time) =>{
        if(time){
        if(time.length===5){
            let tempDateString = freight.JobDate + ' ' + time;
            return new Date(tempDateString);
        }else return time;
        }

    }
    function mapQuillAttributes( html, ops){
    
        let tag = "";
        let openTags =[];
        //  console.log('this is the ops when mapping', ops);
        ops.forEach((op,index) => {
            const { insert, attributes } = op; 
            if (attributes) {
                Object.keys(attributes).forEach(attr => {
                if (attr === "bold") {
                    tag += "<strong>";
                    openTags.push("</strong>");
                } else if (attr === "italic") {
                    tag += "<em>";
                    openTags.push("</em>");
                } else if (attr === "underline") {
                    tag += "<u>";
                    openTags.push("</u>");
                } else if (attr === "color") {
                    tag += `<span style="color: ${attributes.color}">`;
                    openTags.push('</span>');
                } else if (attr === "background") {
                    tag += `<span style="background-color: ${attributes.background}">`;
                    openTags.push('</span>');
                }
                });
            }
        
            html += `${tag}${insert}${openTags.reverse().join('')}`;
            })
        return html;
        
    }
  
    function quillOpsToHtml(ops) {
        let html = "";
        let openTags = [];
        console.log('ops BEFORE = ', ops)
        if (!Array.isArray(ops)) {
           console.log('Invalid ops data:', ops);
            return '';
        }
        if(ops?.length>0){
            console.log('ops = ', ops)
            ops.forEach((op,index) => {
            const { insert, attributes } = op;

            if (insert.includes('\n')) {
                const insertParts = insert.split('\n');
                insertParts.forEach((part, i) => {
                    console.log('part = ', part)

                    if (part)   html = `${mapQuillAttributes( html, [{ insert: part }])}`;
                    // Add opening and clsoing paragraph tag for new line
                    if (i < insertParts.length - 1)   html += `<br>`;
                });
            
            } else {
            
                html = `${mapQuillAttributes(html, [op])}`;
                if (index === ops.length - 1)  html += "</p>";   
                openTags = [];
            }
            });
            console.log('html = ', html)
            return html;
        }else return '';
       
    }

    const fetchFileFromUrl = ( weight) => {
        fetch(weight.TagUrl)
        .then((response) => response.blob())
        .then((blob) => {
    
            const file = new File([blob], "weight"+weight.loadNumber+"TagPic.jpg", { type: "image/jpeg" });
            weight.selectedFiles=[file];
            return file;
        
        }) .catch((error) => console.error(error));
    };

    const compressImage = async (file) => {

        const options = {
            maxSizeMB: 1.0, // Maximum file size in MB
            maxWidthOrHeight: 1024, // Resize to this width/height (keeping aspect ratio)
            useWebWorker: true, 
            initialQuality: 0.9,
        };
        // const jpegBlob = new Blob([blob], { type: 'image/jpeg' });
    
        return await imageCompression( file, options);
    };

    const uploadWeightFile = async(file,weight)=>{
        console.log('file = ', file)
        let tempFile=  await  compressImage(file)
        let fileName = +weight.loadNumber.toString()+file.name;
        let storageRef = ref(storage, 'attachments/'+freightBill.companyID+'/FreightBills/' + freightBill.ID+'/Weights/'+fileName);
        uploadBytes(storageRef,tempFile).then((snapshot) => {
        getDownloadURL(storageRef).then((url) => {
            console.log('Uploaded a blob or file!', url);
            weight.TagUrl=url;
            console.log('wait how does the weight lookd = ', weight);
            console.log('Freightbill.weights = ', freightBill.Weights);
            updateFreightBillField('Weights',freightBill.Weights);
        });
        });
    }

    const uploadFreightFile = async(file)=>{
        console.log('file = ', file)
        let storageRef = ref(storage, 'attachments/'+freightBill.companyID+'/FreightBills/' + freightBill.ID+'/'+file.name);
        uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(storageRef).then((url) => {
            console.log('Uploaded a blob or file!', url);
            freightBill.Picture=url;
            console.log('Freightbill.Picture = ', freightBill.Picture);
            updateFreightBillField('Picture',freightBill.Picture);
        });
        });
    }

    const updateQuillNotes = async(fieldName,rawText, quillFieldName, quillFieldValue ) =>{
        const freightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/DriverFreightBills', freightBill.ID);
        await updateDoc(freightRef, { 
            timestamp:Date.now(),
            [fieldName]:  rawText, 
            [quillFieldName]:quillFieldValue, 
    
            fromOfficeUser:false
        }).then((tempFreight) => {
        console.log('hmmm mtemp freigth = ', tempFreight);
        });
    }

    const convertReactDate = (reactDate) =>{
        if(reactDate!='' && reactDate!="NaN:NaN"){
        var date = new Date(reactDate);
        console.log('convertin date = ' , reactDate);
        if(!date.getHours())return reactDate;
        var hours, minutes;
        if(Number(date.getHours())<10)hours = "0" + date.getHours();
        else  hours = date.getHours();

        if(Number(date.getMinutes())<10)minutes = "0" + date.getMinutes();
        else minutes =  date.getMinutes().toString();

        var formattedTime = hours + ':' + minutes.substring(-2);
        console.log('formatted Time = ' + formattedTime)

        return formattedTime;
        }else return '';

    }

    const updateFreightBillWeights = async() =>{
        const freightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/DriverFreightBills', freightBill.ID);
        let progressLoads=0;
        let progressTons =0;
        let deliveredLoads=0;
        let deliveredTons =0;
        setFreightBill(prevState => ({...prevState,  Weights:freightBill.Weights }));
        console.log('setting status of freigtbhill = ' , freightBill.Weights);
        if(!freightBill.AverageRoundTrip && freightBill.AverageRoundTrip!==0) freightBill.AverageRoundTrip = '';
        if(!freightBill.AverageDumpTime && freightBill.AverageDumpTime!==0) freightBill.AverageDumpTime = '';
        if(!freightBill.AverageLoadTime && freightBill.AverageLoadTime!==0) freightBill.AverageLoadTime= '';
        if(!freightBill.mobileStartTime)freightBill.mobileStartTime='';
        if(!freightBill.paidStandExMin)freightBill.paidStandExMin=0;
        if(!freightBill.paidExpenses)freightBill.paidExpenses=0;
        if(!freightBill.billedExpenses)freightBill.billedExpenses=0;
        if(!freightBill.hoursWorked)freightBill.hoursWorked=0;
        if(!freightBill.paidHoursWorked)freightBill.paidHoursWorked=0;
    
        if(!freightBill.StatusTime)freightBill.StatusTime='';
        else  freightBill.StatusTime=convertReactDate(freightBill.StatusTime);
        for(let i=0; i<freightBill.Weights.length; i++){
        let calcWeight = {...freightBill.Weights[i]};
        if(checkGearedTime(calcWeight.dumpEnd)){
            deliveredLoads++; 
        
            deliveredTons+=Number(calcWeight.weight)
        }else{
            progressLoads++;
            progressTons+=Number(calcWeight.weight)
        } 
        }
        if(!freightBill.totalYardHours)freightBill.totalYardHours=0;
        if(!freightBill.billedExpenses)freightBill.billedExpenses=0;
        if(!freightBill.tFee)freightBill.tFee=0;
        if(!freightBill.bFee)freightBill.bFee=0;
        if(!freightBill.fuelBilled)freightBill.fuelBilled=0;
        if(!freightBill.profit)freightBill.profit=0;
        if(!freightBill.paidBrokerFee)freightBill.paidBrokerFee=0;
        if(!freightBill.totalExcessLoad)freightBill.totalExcessLoad=0;
        if(!freightBill.totalExcessDump)freightBill.totalExcessDump=0;

        if(!freightBill.deliveredLoads)freightBill.deliveredLoads=0;
        if(!freightBill.progressLoads)freightBill.progressLoads=0;
        if(!freightBill.deliveredTons)freightBill.deliveredTons=0;
        if(!freightBill.progressTons)freightBill.progressTons=0;
        let nowDate = Date.now();

        console.log('end time = ',freightBill.endTime )
        console.log('start time = ',freightBill.startTime )
        console.log('arriveRoundTrip = ',freightBill.arriveRoundTrip)
        console.log('departRoundTrip = ',freightBill.departRoundTrip)
        console.log('nowDAate===  ',nowDate)

        await updateDoc(freightRef, {  
            Weights:freightBill.Weights,
            runningTime:freightBill.runningTime, 
            endTime:freightBill.endTime,
            startTime:freightBill.startTime,
            timestamp: nowDate,
            fromOfficeUser:false,
            loads:freightBill.loads,
            deliveredLoads: deliveredLoads,
            progressLoads: progressLoads,
            deliveredTons: deliveredTons,
            progressTons: progressTons,
            billedQty: freightBill.billedQty,
            billedExpenses:freightBill.billedExpenses,
            tBilled: freightBill.tBilled,
            standExMin: freightBill.standExMin,
            paidStandExMin: freightBill.paidStandExMin,
            standBilled: freightBill.standBilled,
            standPaid:freightBill.standPaid,
            totalDriverPay:freightBill.totalDriverPay,
            paidExpenses:freightBill.paidExpenses,
            paidQty:freightBill.paidQty,
            tFee:freightBill.tFee,
            bFee:freightBill.bFee,
            profit:freightBill.profit,
            paidBrokerFee:freightBill.paidBrokerFee,
            fuelBilled: freightBill.fuelBilled,
            arriveRoundTrip:freightBill.arriveRoundTrip,
            departRoundTrip:freightBill.departRoundTrip,
            AverageDumpTime:freightBill.AverageDumpTime,
            AverageLoadTime:freightBill.AverageLoadTime,
            AverageRoundTrip:freightBill.AverageRoundTrip,
            totalExcessDump:freightBill.totalExcessDump,
            totalExcessLoad:freightBill.totalExcessLoad,
            StatusTime: freightBill.StatusTime,
            Status: freightBill.Status,
            tPaid:freightBill.tPaid,
            bTotal: freightBill.bTotal,
            totalYardHours: freightBill.totalYardHours,
            tHours: freightBill.tHours,
            travelTime: freightBill.travelTime,
        
            lunch: freightBill.lunch,
            grossHours: freightBill.grossHours,
            tHoursPaid:freightBill.tHoursPaid,
            hoursWorked:freightBill.hoursWorked,
            paidHoursWorked: freightBill.paidHoursWorked,
            tWeight: freightBill.tWeight
        }).then((tempFreight) => {
            console.log('hmmm mtemp freigth = ', tempFreight);
        });
        
    }

    const updateFreightBillField =  async(fieldName,value, updateOfficeFreight) => {
        setFreightBill(prevState => ({...prevState,  [fieldName]: value }));
        freightBill[fieldName] = value;
    
        const freightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/DriverFreightBills', freightBill.ID);
        if(updateOfficeFreight ){
            const officeFreightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/FreightBills', freightBill.ID);
            updateDoc(officeFreightRef, {timestamp:Date.now(), [fieldName]: value })
        }
        await updateDoc(freightRef, {timestamp:Date.now(), [fieldName]: value ,fromOfficeUser:false}).then((tempFreight) => {
        console.log('hmmm mtemp freigth = ', freightBill[fieldName]);
        
        });
    };

    const updateFreightBillFields =  async(fields,values, updateOfficeFreight) => {
        setFreightBill(prevState => {
        let newState = { ...prevState };
        for (let i = 0; i < fields.length; i++) {
            newState[fields[i]] = values[i];
        }
        return newState;
        });
        const updateObject = {};
        fields.forEach((field, index) => {
        updateObject[field] = values[index];
        });


        updateObject.timestamp = Date.now();
        updateObject.fromOfficeUser=false;
        console.log('updateObject= ',updateObject);
        if(updateOfficeFreight ){
            const officeFreightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/FreightBills', freightBill.ID);
            updateDoc(officeFreightRef,  updateObject)
        }
        const freightRef = doc(db, 'Organizations/'+ freightBill.companyID+'/DriverFreightBills', freightBill.ID);
        await updateDoc(freightRef, updateObject).then((tempFreight) => {
        console.log('hmmm mtemp freigth = ', tempFreight);
        });
    };
  
    const clockOutFB = () =>{
        console.log('HELLOOOO!!!!1')
        const secondsSinceEpoch = Math.round(Date.now() / 1000);
        let fullDate = new Date().toString();
        let newAuditLog={
            User:gearedUser.Name,
            ID:secondsSinceEpoch.toString(),
            DateAndTime: fullDate,
            ActivityType:"Submit Mobile FB", 
            ObjectType:"FreightBills",
            Value: freightBill
        }
        const auditLogRef = doc(db, 'Organizations/'+ freightBill.companyID+'/AuditLogs/FreightBills/'+ freightBill.ID, secondsSinceEpoch.toString());
        setDoc(auditLogRef,  newAuditLog);
        var tempFields = ['dSubmitted','Status', 'StatusTime'];
        var tempValues = [true,'Submitted', convertReactDate(Date.now())];
        updateFreightBillFields(tempFields,tempValues, true);
        // updateFreightBillField('dSubmitted', true);
        setSubmitStatus('Entry');
        setActiveIndex(1);
        navToPaperlessHome();
    }

    const navToPaperlessHome=()=>{   
        console.log('is there a home rout?')
        if ( unsubscribeFreightBillRef.current) unsubscribeFreightBillRef.current();

        navigate(`/home`);  
    }

    useEffect(() => {
        setOptions({ theme: 'ios', themeVariant:"light" });
    });
    useEffect(() => {
        console.log('truckFreightBills=', truckFreightBills);
    }, [truckFreightBills])

  return (
    <PaperlessContext.Provider value={{ uploadPDFFile, setFreightBill, setDriverNotes, setApproverNotes, driverNotes, approverNotes, fetchNoDispatch, navToPaperlessHome, 
    getDifferenceInMinutes, clockOutFB, viewPDF, setApproveType, setSubmitStatus, setNavListItems, navListItems,convertReactDate, setTruckFreightBills, truckFreightBills, 
    submitStatus, activeIndex, setActiveIndex, approveType, pdfUrl,createPDF, setHomeDate,setPaperlessState,paperlessState, homeDate, updateFreightBillFields,
    checkGearedTime, loadOrders,  freightBill, fetchTrucksFreightBill, fetchFreightBill, updateFreightBillField, updateAuditLog, 
    uploadWeightFile,uploadFreightFile, updateFreightBillWeights,updateQuillNotes, attachments
    
    }}>
      {children}
    </PaperlessContext.Provider>
  );
};

export const usePaperless= () => {
  return useContext(PaperlessContext);
};
