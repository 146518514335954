import React, {useRef,  useState, useEffect} from 'react';
import { Editor } from 'primereact/editor';


const NoteComponent = ({ parent, noteType,quillName, onNoteChange, disabled = false }) => {
    const noteRef = useRef(null)

    const [quillNotes, setQuillNotes] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [quillInstance, setQuillInstance] = useState(null);
    const noteText = parent[noteType];
    const quillValue = parent[quillName];

    
    useEffect(()=>{

        if(quillInstance)if(noteText!==quillInstance.getText()) updateQuillNotes()
           
    },[noteText])

    const updateQuillNotes = async()=>{
        let newValue =await quillOpsToHtml(quillValue);
        noteRef?.current?.getQuill()?.setContents(quillValue)
        setQuillNotes(newValue);
        
    }

  // console.log('note ocmponent disabled = '+ disabled)
  //  console.log('quillNotes when loading ', quillNotes)
  
   const saveNotes = (textValue) => {

        if (noteRef.current) {
            const quillInstance = noteRef.current.getQuill();
            console.log('like wtf is the note cahgne = ',  quillInstance.editor.delta.ops)
            onNoteChange(noteType, textValue,quillName, quillInstance.editor.delta.ops);
        }
        
    }; 

    function mapQuillAttributes( html, ops){
    
        let tag = "";
        let openTags =[];
        //  console.log('this is the ops when mapping', ops);
        ops.forEach((op,index) => {
            const { insert, attributes } = op; 
            if (attributes) {
                Object.keys(attributes).forEach(attr => {
                if (attr === "bold") {
                    tag += "<strong>";
                    openTags.push("</strong>");
                } else if (attr === "italic") {
                    tag += "<em>";
                    openTags.push("</em>");
                } else if (attr === "underline") {
                    tag += "<u>";
                    openTags.push("</u>");
                } else if (attr === "color") {
                    tag += `<span style="color: ${attributes.color}">`;
                    openTags.push('</span>');
                } else if (attr === "background") {
                    tag += `<span style="background-color: ${attributes.background}">`;
                    openTags.push('</span>');
                }
                });
            }
        
            html += `${tag}${insert}${openTags.reverse().join('')}`;
            })
        return html;
        
    }
  
    function quillOpsToHtml(ops) {
        let html = "";
        let openTags = [];
        console.log('ops BEFORE = ', ops)
        if (!Array.isArray(ops)) {
           console.log('Invalid ops data:', ops);
            return '';
        }
        if(ops?.length>0){
            console.log('ops = ', ops)
            ops.forEach((op,index) => {
            const { insert, attributes } = op;

            if (insert.includes('\n')) {
                const insertParts = insert.split('\n');
                insertParts.forEach((part, i) => {
                    console.log('part = ', part)

                    if (part)   html = `${mapQuillAttributes( html, [{ insert: part }])}`;
                    // Add opening and clsoing paragraph tag for new line
                    if (i < insertParts.length - 1)   html += `<br>`;
                });
            
            } else {
            
                html = `${mapQuillAttributes(html, [op])}`;
                if (index === ops.length - 1)  html += "</p>";   
                openTags = [];
            }
            });
            console.log('html = ', html)
            return html;
        }else return '';
       
    }
    const loadQuill = (e) => {
        const quillInstance = noteRef.current.getQuill();
        setQuillInstance(quillInstance);
        console.log('quillInstance on load = ', quillInstance)
        // Attach a text-change event listener to capture paste events
        quillInstance.on('text-change', (delta, oldDelta, source) => {
            console.log('text change on the quill is firing')
            if (source === 'user') { // Only handle user-triggered changes
                const textValue = quillInstance.getText();
                console.log('text change on the quill is firing', quillInstance.getText())
                console.log('tequill instance ', quillInstance)
                saveNotes(textValue);
            }
        });
       setQuillNotes(quillOpsToHtml(quillValue))    
    }
   const renderHeader = () => {
          return (
              <div height="0"></div>
          );
      }; 
  
      const header = renderHeader();

    return (
        <div >
            <Editor    key={disabled ? 'editor-readonly' : 'editor-editable'} readOnly={disabled} headerTemplate={header} text={noteText} value={quillNotes} onLoad={loadQuill} ref={noteRef}  style={{ height: '320px' }} />
        </div>
    );
};

export default NoteComponent;